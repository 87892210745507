import { Injectable } from '@angular/core';

import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiResponse } from '@runway/productcommon';
import { R6ApiService } from '@runway/r6api';

import { EstateAsset } from './model/estate-asset.model';
import { EstateBranch } from './model/estate-branch.model';
import { EstateDetail } from './model/estate-detail.model';
import { EstateDrip } from './model/estate-drip.model';
import { EstateSalesRep } from './model/estate-rep.model';
import { EstateSummary } from './model/estate-summary.model';

@Injectable({
  providedIn: 'root',
})
export class EstateService {

  private readonly estateSummaryList: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi/estatesummary/list';
  private readonly estateSummaryGet: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi/estatesummary/get';
  private readonly estateDetailGet: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi/estate/get';
  private readonly estateDetailPost: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi/estate/publish';
  private readonly estateAssetPost: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi/estate/publish/asset';
  private readonly estateDripPost: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi/estate/publish/drip';
  private readonly estateBranchPost: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi/estate/publish/branch';
  private readonly estateSalesRepPost: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi/estate/publish/reps';
  private readonly estateSummaryV2List: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi-v2/estatesummary/list';
  private readonly estateSummaryV2GetByCpId: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi-v2/estatesummary/get';
  private readonly estateDetailV2GetByCpId: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi-v2/estate/get';
  private readonly estateAssetV2Post: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi-v2/estate/publish/asset';
  private readonly estateDripV2Post: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi-v2/estate/publish/drip';
  private readonly estateBranchV2Post: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi-v2/estate/publish/branch';
  private readonly estateSalesRepV2Post: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi-v2/estate/publish/reps';
  private readonly estateDetailV2Post: string = this.r6apiService.r6ApiParams.getR6ApiUrl() + '/landapi-v2/estate/publish';

  private readonly clientProductIdParam = 'clientproductid';
  private readonly estateCpIdParam = 'estatecpid';
  private readonly r4ProductIdParam = 'r4productid';

  constructor(private r6apiService: R6ApiService) {
  }

  listEstateSummary(): Observable<EstateSummary[]> {
    const estateSummaryListUrl = this.estateSummaryList;
    return this.r6apiService.get(estateSummaryListUrl);
  }

  listEstateSummaryV2(): Observable<EstateSummary[]> {
    const estateSummaryV2ListUrl = this.estateSummaryV2List;
    return this.r6apiService.get(estateSummaryV2ListUrl);
  }

  getEstateSummary(clientProductId: string): Observable<EstateSummary> {
    return this.r6apiService.get(this.estateSummaryGet, new HttpParams().set(this.clientProductIdParam, clientProductId));
  }

  getEstateSummaryV2(estateCpId: string): Observable<EstateSummary> {
    return this.r6apiService.get(this.estateSummaryV2GetByCpId, new HttpParams().set(this.estateCpIdParam, estateCpId));
  }

  getEstateDetailByR4ProductId(r4ProductId: string): Observable<EstateDetail> {
    return this.r6apiService.get(this.estateDetailGet, new HttpParams().set(this.r4ProductIdParam, r4ProductId));
  }

  getEstateDetail(clientProductId: string): Observable<EstateDetail> {
    return this.r6apiService.get(this.estateDetailGet, new HttpParams().set(this.clientProductIdParam, clientProductId));
  }

  getEstateDetailV2(estateCpId: string): Observable<EstateDetail> {
    const params: HttpParams = new HttpParams()
      .set('estatecpid', estateCpId);
    return this.r6apiService.get(this.estateDetailV2GetByCpId, params);
  }

  saveEstateDetail(estateDetail: EstateDetail): Observable<ApiResponse> {
    const body = JSON.stringify(estateDetail);
    return this.r6apiService.post(this.estateDetailPost, body);
  }

  saveEstateDetailV2(estateDetail: EstateDetail): Observable<ApiResponse> {
    const body = JSON.stringify(estateDetail);
    return this.r6apiService.post(this.estateDetailV2Post, body);
  }

  saveEstateAsset(estateAsset: EstateAsset): Observable<ApiResponse> {
    const body = JSON.stringify(estateAsset);
    return this.r6apiService.post(this.estateAssetPost, body);
  }

  saveEstateAssetV2(estateAsset: EstateAsset): Observable<ApiResponse> {
    const body = JSON.stringify(estateAsset);
    return this.r6apiService.post(this.estateAssetV2Post, body);
  }

  saveEstateDrip(estateDrip: EstateDrip): Observable<ApiResponse> {
    const body = JSON.stringify(estateDrip);
    return this.r6apiService.post(this.estateDripPost, body);
  }

  saveEstateDripV2(estateDrip: EstateDrip): Observable<ApiResponse> {
    const body = JSON.stringify(estateDrip);
    return this.r6apiService.post(this.estateDripV2Post, body);
  }

  saveEstateBranch(estateBranch: EstateBranch): Observable<ApiResponse> {
    const body = JSON.stringify(estateBranch);
    return this.r6apiService.post(this.estateBranchPost, body);
  }

  saveEstateBranchV2(estateBranch: EstateBranch): Observable<ApiResponse> {
    const body = JSON.stringify(estateBranch);
    return this.r6apiService.post(this.estateBranchV2Post, body);
  }

  saveEstateSalesRep(estateSalesRep: EstateSalesRep): Observable<ApiResponse> {
    const body = JSON.stringify(estateSalesRep);
    return this.r6apiService.post(this.estateSalesRepPost, body);
  }

  saveEstateSalesRepV2(estateSalesRep: EstateSalesRep): Observable<ApiResponse> {
    const body = JSON.stringify(estateSalesRep);
    return this.r6apiService.post(this.estateSalesRepV2Post, body);
  }

}
