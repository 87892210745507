import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

import { AuthService } from '../auth.service';

@Component({
  template: `<div>Dont rely on this. Extend it.</div>`,
})
export class ChangePasswordComponent implements OnInit {

  apiError = '';
  passwordChanged = false;
  disabled = false;

  changePasswordForm = new FormGroup({
      currentPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
    },
    [this.checkPasswords],
  );

  constructor(private authService: AuthService, private location: Location) {
  }

  ngOnInit() {
    this.apiError = '';
    this.passwordChanged = false;
  }

  onChangePassword() {
    this.disabled = true;
    this.authService.changePassword(this.changePasswordForm.get('currentPassword').value, this.changePasswordForm.get('newPassword').value).subscribe(res => {
      if (!res || !res.success) {
        this.apiError = res.errormessage;
        this.passwordChanged = false;
        this.disabled = false;
      } else {
        this.passwordChanged = true;
      }
    }, errorRes => {
      this.apiError = errorRes.error && errorRes.error.errormessage ? errorRes.error.errormessage : 'Password change error.';
      this.passwordChanged = false;
      this.disabled = false;
    });
  }

  cancel() {
    this.location.back();
  }

  checkPasswords(group: FormGroup) {
    const notSameValidationError = group.controls.newPassword.value !== group.controls.confirmPassword.value;
    const sameAsCurrentValidationError = group.controls.newPassword.value === group.controls.currentPassword.value;
    if (notSameValidationError || sameAsCurrentValidationError) {
      return {
        notSame: notSameValidationError,
        sameAsCurrent: sameAsCurrentValidationError,
      };
    }
    return null;
  }
}
