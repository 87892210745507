import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

declare const $: any;

@Directive({
    selector: '[appCapsuleSlider]',
})
export class CapsuleSliderDirective implements OnInit {

    @Input() options: any;

    constructor(private el: ElementRef) {
    }

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        event.preventDefault();
    }

    ngOnInit() {
        this.handleOnInit();
    }

    private handleOnInit() {
        if (this.options) {
            $('.master-slider').masterslider(this.options);
        } else {
            $('.master-slider').masterslider({
                width: 1000,
                view: 'parallaxMask',
                fillMode: 'fill',
                fullwidth: true,
                autoHeight: true,
                autoplay: true,
                loop: true,
            });
        }
        $('.ms-slide-layers').removeAttr('style');
    }
}
