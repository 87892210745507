export class PaginationHelperService {

  public static buildDisplayArray<T>(list: T[], itemsPerPage: number): T[] {
    if (list.length < itemsPerPage) {
      return list;
    } else {
      return list.slice(0, itemsPerPage);
    }
  }
}
