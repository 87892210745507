import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

declare const $: any;

@Directive({
    selector: '[appCapsuleMasonary]',
})
export class CapsuleMasonaryDirective implements AfterViewInit {
    @Input('masonaryGridClass') masonaryGridClass: string;

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit() {
        this.triggerOnInit();
    }

    private triggerOnInit() {
        $('.' + this.masonaryGridClass).masonry({
            itemSelector: '.boxes',
        });
    }
}
