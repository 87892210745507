import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { EstateService } from '../estate.service';

import { EstateDetail } from '../model/estate-detail.model';
import { EstateSummary } from '../model/estate-summary.model';

@Component({
  selector: 'r6nm-estate-detail',
  template: '<div>Estate Detail to be extended</div>'
})
export class EstateDetailComponent implements OnInit {

  estateDetail: EstateDetail;
  estateSummary: EstateSummary;
  estateDetailV2: EstateDetail;
  estateSummaryV2: EstateSummary;
  estateCpId: string;
  theme: string;

  constructor(public estateService: EstateService, public activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.estateCpId = params['id'];
      this.theme = params['theme'];
    });

    this.fetchEstateDetail();
    this.fetchEstateSummary();
    this.fetchEstateDetailV2();
    this.fetchEstateSummaryV2();
  }

  fetchEstateDetail() {
    this.estateDetail = new EstateDetail();
    this.estateService.getEstateDetail(this.estateCpId)
      .subscribe((estateDetail) => this.estateDetail = estateDetail);
  }

  fetchEstateSummary() {
    this.estateSummary = new EstateSummary();
    this.estateService.getEstateSummary(this.estateCpId)
      .subscribe((estateSummary) => this.estateSummary = estateSummary);
  }

  fetchEstateDetailV2() {
    this.estateDetailV2 = new EstateDetail();
    this.estateService.getEstateDetailV2(this.estateCpId)
      .subscribe((estateDetailV2) => this.estateDetailV2 = estateDetailV2);
  }

  fetchEstateSummaryV2() {
    this.estateSummaryV2 = new EstateSummary();
    this.estateService.getEstateSummaryV2(this.estateCpId)
      .subscribe((estateSummaryV2) => this.estateSummaryV2 = estateSummaryV2);
  }

}
