export class UrltokenModel {
  urltoken: string;
  accessid: string;
  accessname: string;
  status: boolean;
  threshold: number;
  counter: number;
  authrequired: boolean;
  statusmessage: string;
  redirectionurl: string;
  urltokentypeid: string;
  entityid: string;
  producttypeid: string;
}
