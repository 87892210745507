export class Dimension {
  productdimensionid: number;
  dimensionnameid: number;
  dimensionname: string;
  dimensiontype: string;
  charvalue: string;
  name: string;
  type: string;
  value: string;
}
