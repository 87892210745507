export class AccessClientModel {
  accessId: string;
  clientId: string;
  clientName: string;
  clientLogoUrl: string;
  active: boolean;
  accessClientId: string;
  managingClientId: string;
}

export class AccesstokenModel {
  securitytoken: string;

  accessid: number;
  accessname: string;

  clientid: string;
  clientcompanyid: string;

  contactname: string;
  companyname: string;
  runwayclient: boolean;

  title: string;
  firstname: string;
  lastname: string;

  authenticated: boolean;
  errorcode: string;
  errormessage: string;

  accessClientModels: AccessClientModel[];
}
