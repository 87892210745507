import { Pipe, PipeTransform } from '@angular/core';

import { isNullOrUndefined } from 'util';
import { EstateSummary } from '../model/estate-summary.model';

@Pipe({
  name: 'estateListFilter'
})
export class EstateListFilterPipe implements PipeTransform {

  transform(estateList: EstateSummary[], filterCriteria: string): any {
    if (isNullOrUndefined(filterCriteria)) {
      return estateList;
    }

    return estateList.filter((estate) => {
      if (!estate.regionName || isNullOrUndefined(estate.regionName)) {
        return false;
      }
      return estate.regionName.toLowerCase().includes(filterCriteria.toLowerCase());
    });
  }
}

