import {Type} from '@angular/core';

import {isNullOrUndefined} from 'util';

import {EstateTheme} from './theme.model';

export abstract class EstateDetailThemeService {

  protected estateThemes: EstateTheme[] = [];

  constructor() {
    this.loadThemeMapping();
  }

  abstract getDefaultComponent(): Type<any>;

  abstract loadThemeMapping(): void;

  getThemeForEstate(estateCpId: string): Type<any> {
    const theme = this.estateThemes.find((themeModel) => themeModel.estateCpId === estateCpId);
    if (isNullOrUndefined(theme)) {
      return this.getDefaultComponent();
    }
    return theme.component;
  }

  getThemeByName(themeName: string): Type<any> {
    const theme = this.estateThemes.find((themeModel) => themeModel.themeName === themeName);
    if (isNullOrUndefined(theme)) {
      return this.getDefaultComponent();
    }
    return theme.component;
  }

  getThemeByEstate(estateCpId: string): string {
    const theme: EstateTheme = this.estateThemes.find((themeModel) => themeModel.estateCpId === estateCpId);
    if (!isNullOrUndefined(theme)) {
      return theme.themeName;
    }
    const defaultEstateConfig = this.estateThemes.find((themeModel) => !isNullOrUndefined(themeModel.default) && themeModel.default === true);
    if (!isNullOrUndefined(defaultEstateConfig)) {
      return defaultEstateConfig.themeName;
    }
    return null;
  }
}
