import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { R6ApiModule } from '@runway/r6api';
import { CommonUtilsModule } from '@runway/commonutils';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './login/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UrlVerificationComponent } from './url-verification/url-verification.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonUtilsModule,
    R6ApiModule,
  ],
  declarations: [ChangePasswordComponent, LoginComponent, LogoutComponent, ResetPasswordComponent, UrlVerificationComponent, ResetPasswordComponent, UrlVerificationComponent],
  exports: [ChangePasswordComponent, LoginComponent, LogoutComponent, ResetPasswordComponent, UrlVerificationComponent],
})
export class R6AuthModule {
}
