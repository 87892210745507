import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { isNullOrUndefined } from 'util';

import { CookieService } from '@runway/commonutils';
import { R6ApiParams } from '@runway/r6api';

import { CookieParams } from './cookie-params.enum';

import { AccesstokenModel } from './model/access-token.model';

@Injectable({
  providedIn: 'root',
})
export class AuthSessionService {

  private loginSuccessTrigger = new Subject<Boolean>();

  constructor(public r6ApiParams: R6ApiParams, private cookieService: CookieService) {
  }

  public setLoginSession(accesstokenModel: AccesstokenModel, numberOfRememberMeDays) {
    this.cookieService.set(CookieParams.signOnSession, JSON.stringify(accesstokenModel), numberOfRememberMeDays);
    this.cookieService.set(CookieParams.signOnSession, JSON.stringify(accesstokenModel), numberOfRememberMeDays);
    this.cookieService.set(CookieParams.r6ClientId, accesstokenModel.clientid, numberOfRememberMeDays);
    this.cookieService.set(CookieParams.r6LoginUserId, accesstokenModel.accessid.toString(), numberOfRememberMeDays);
    this.cookieService.set(CookieParams.r6SecurityToken, accesstokenModel.securitytoken, numberOfRememberMeDays);
    this.cookieService.set(CookieParams.r6AccessName, accesstokenModel.accessname, numberOfRememberMeDays);
    this.cookieService.set(CookieParams.r6UserName, accesstokenModel.title + ' ' + accesstokenModel.firstname + ' ' + accesstokenModel.lastname, numberOfRememberMeDays);
    this.r6ApiParams.updateR6SecurityToken(accesstokenModel.securitytoken);
    this.triggerAuthenticationSuccessEvent();
  }

  public getLoginSession() {
    return JSON.parse(this.cookieService.get(CookieParams.signOnSession));
  }

  public setSelectedClient(selectedClientId: string, selectedClientName: string) {
    this.cookieService.set(CookieParams.selectedClientId, selectedClientId);
    this.cookieService.set(CookieParams.selectedClientName, selectedClientName);
  }

  public getSelectedClientID() {
    return this.cookieService.get(CookieParams.selectedClientId);
  }

  public getSelectedClientName() {
    return this.cookieService.get(CookieParams.selectedClientName);
  }

  public getLoginusername() {
    return this.cookieService.get(CookieParams.r6UserName);
  }

  public getLoginUserId() {
    return this.cookieService.get(CookieParams.r6LoginUserId);
  }

  public isLoggedIn(): boolean {
    let securityToken = this.cookieService.get(CookieParams.r6SecurityToken);
    if (isNullOrUndefined(securityToken)) {
      securityToken = this.cookieService.get(CookieParams.securityToken);
    }
    const loginUserId = this.cookieService.get(CookieParams.r6LoginUserId);
    this.r6ApiParams.updateR6SecurityToken(securityToken);

    const isCachedAuth = !isNullOrUndefined(securityToken) && securityToken.trim().length !== 0 && !isNullOrUndefined(loginUserId) && loginUserId.length !== 0;
    if (isCachedAuth) {
      this.triggerAuthenticationSuccessEvent();
    }
    return isCachedAuth;
  }

  public performSignOut() {
    this.cookieService.deleteAll(CookieParams.signOnSession);
    this.cookieService.deleteAll(CookieParams.r6ClientId);
    this.cookieService.deleteAll(CookieParams.r6LoginUserId);
    this.cookieService.deleteAll(CookieParams.r6SecurityToken);
    this.cookieService.deleteAll(CookieParams.r6AccessName);
    this.cookieService.deleteAll(CookieParams.r6UserName);
  }

  public observeSuccessfuleAuthentication(): Observable<Boolean> {
    return this.loginSuccessTrigger.asObservable();
  }

  public triggerAuthenticationSuccessEvent() {
    this.loginSuccessTrigger.next(true);
  }
}
