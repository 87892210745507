import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthSessionService } from './auth-session.service';

import { AuthRoutingParams } from './model/auth-routing-params.model';

@Injectable({
  providedIn: 'root',
})
export class AuthRouteGuard implements CanActivate {

  constructor(public router: Router, public loginRoutingParams: AuthRoutingParams, public loginSessionService: AuthSessionService) {
  }

  canActivate() {
    if (this.loginSessionService.isLoggedIn()) {
      return true;
    }

    this.router.navigate([this.loginRoutingParams.getLoginErrorRoute()]);
    return false;
  }
}
