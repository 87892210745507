export const enerygyRatingDimension = {
  name: 'Energy Efficiency Rating',
  type: 'NumberOf',
};
export const canFitOnDimension = {
  name: 'Can Fit On',
  type: 'Width',
};
export const livingAreaDimension = {
  name: 'Living Area',
  type: 'SQmeters',
};
export const masterBedroomAreaDimension = {
  name: 'Master Bedroom',
  type: 'Cubicmeters',
};
export const garageAreaDimension = {
  name: 'Garage',
  type: 'SQmeters',
};
export const sectionDimension = {
  name: 'Section',
  type: 'Alphavalue',
};
export const blockTypeDimension = {
  name: 'Block Type',
  type: 'Alphavalue',
};
export const blockWidthDimension = {
  name: 'Lot',
  type: 'Width',
};
export const blockDepthDimension = {
  name: 'Lot',
  type: 'Depth',
};
export const blockAreaDimension = {
  name: 'Lot',
  type: 'SQmeters',
};
