import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { isNullOrUndefined } from 'util';

import { R6ApiParams } from '@runway/r6api';

import { AccesstokenModel } from '../model/access-token.model';
import { AuthService } from '../auth.service';
import { SignonRequest } from '../model/signon-request.model';
import { UrltokenModel } from '../model/url-token.model';

@Component({
  template: `
      <div>Dont rely on this. Extend it.</div>`,
})
export class UrlVerificationComponent implements OnInit {

  errorMessage: string;
  accessTokenModel: AccesstokenModel;
  passwordChanged = false;

  urlVerifiedChangePasswordForm = new FormGroup({
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
    },
    [this.checkPasswords],
  );

  private readonly accessIdParam = 'accessId';
  private readonly urlTokenParam = 'urltoken';

  private readonly invalidTokenError = 'URL Token is either unavailable or not valid.';

  constructor(private r6ApiParams: R6ApiParams, private authService: AuthService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const accessId = params[this.accessIdParam];
      const urlToken = params[this.urlTokenParam];
      if (isNullOrUndefined(urlToken) || urlToken.length === 0 || isNullOrUndefined(accessId) || accessId.length === 0) {
        this.errorMessage = this.invalidTokenError;
        return;
      }
      this.authService.urlTokenValidate(accessId, urlToken).subscribe((urlTokenModel: UrltokenModel) => {
        if (!urlTokenModel.status) {
          this.errorMessage = this.invalidTokenError;
          return;
        }

        this.authService.urlTokenProcess(urlTokenModel.accessid, urlTokenModel.entityid, urlTokenModel.urltoken, urlTokenModel.urltokentypeid).subscribe(response => {
          const signOnRequest = new SignonRequest();
          signOnRequest.accessid = urlTokenModel.accessid;
          signOnRequest.urltokenvalue = urlTokenModel.urltoken;
          this.authService.performSignOn(signOnRequest).subscribe(accessTokenModel => {
            this.accessTokenModel = accessTokenModel;
          }, error => {
            console.log(error);
            this.errorMessage = this.invalidTokenError;
          });
        }, error => {
          console.log(error);
          this.errorMessage = this.invalidTokenError;
          return;
        });
      }, error => {
        console.log(error);
        this.errorMessage = this.invalidTokenError;
        return;
      });
    });
  }

  onChangePassword() {
    this.r6ApiParams.updateR6SecurityToken(this.accessTokenModel.securitytoken);
    this.authService
      .changePassword(null, this.urlVerifiedChangePasswordForm.get('newPassword').value, this.accessTokenModel.accessname, this.accessTokenModel)
      .subscribe(res => {
        this.r6ApiParams.updateR6SecurityToken(null);
        if (!res || !res.success) {
          this.errorMessage = res.errormessage;
          this.passwordChanged = false;
        } else {
          this.passwordChanged = true;
        }
      }, errorRes => {
        this.errorMessage = errorRes.error && errorRes.error.errormessage ? errorRes.error.errormessage : 'Password change error.';
        this.passwordChanged = false;
      });
  }

  private checkPasswords(group: FormGroup) {
    const notSameValidationError = group.controls.newPassword.value !== group.controls.confirmPassword.value;
    if (notSameValidationError) {
      return {
        notSame: notSameValidationError,
      };
    }
    return null;
  }
}
