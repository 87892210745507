import { AddressModel, AssetModel, EventModel } from '@runway/productcommon';
export class EstateDetail {
  estatename: string;
  estatedescription: string;
  clientproductid: number;
  cpidstring: string;
  productid: number;
  clientid: string;
  clientcompanyid: string;
  clientcompanyname: string;
  currentstatusid: number;
  currentstatusname: string;
  clientproducttype: number;
  pricingtype: string;
  marketingstatus: string;
  productsubtype: string;
  productshortaddress: string;
  locationid: number;
  locationname: string;
  r4Locationid: string;
  regionid: string;
  r4Regionid: string;
  regionname: string;
  masterplansvgurl: string;
  childproductcount: number;
  runwayproductid: string;
  publishallowed: boolean;
  importallowed: boolean;
  imported: boolean;
  handoverallowed: boolean;
  handedoverproduct: boolean;
  published: boolean;
  active: boolean;
  address: AddressModel;
  estateimages: AssetModel[];
  estatedocuments: AssetModel[];
  estateevents: EventModel[];
  estatedrips: EventModel[];
}
