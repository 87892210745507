import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoanCalculatorService {

    public calculateWeeklyMortgage(loanAmount: number, annualRate: number, loanPeriodInYears: number): number {
        const weeklyInterest = annualRate / 100 / 48;
        const numberOfPayments = loanPeriodInYears * 48;
        return this.calculate(loanAmount, weeklyInterest, numberOfPayments);
    }

    public calculateMonthlyMortgage(loanAmount: number, annualRate: number, loanPeriodInYears: number): number {
        const weeklyInterest = annualRate / 100 / 12;
        const numberOfPayments = loanPeriodInYears * 12;
        return this.calculate(loanAmount, weeklyInterest, numberOfPayments);
    }

    public calculateFortnightlyMortgage(loanAmount: number, annualRate: number, loanPeriodInYears: number): number {
        const weeklyInterest = annualRate / 100 / 24;
        const numberOfPayments = loanPeriodInYears * 24;
        return this.calculate(loanAmount, weeklyInterest, numberOfPayments);
    }

    private calculate(loanAmount: number, interestPerFrequency: number, numberOfPayments: number): number {
        // Now compute the payment figure, using esoteric math.
        const x = Math.pow(1 + interestPerFrequency, numberOfPayments);
        const repayment = (loanAmount * x * interestPerFrequency) / (x - 1);

        if (!isNaN(repayment) &&
            (repayment !== Number.POSITIVE_INFINITY) &&
            (repayment !== Number.NEGATIVE_INFINITY)) {
            return Math.ceil(repayment);
        }
        return repayment;
    }
}
