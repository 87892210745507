import { Pipe, PipeTransform } from '@angular/core';

import { isNullOrUndefined } from 'util';

import { PaginationHelperService } from './pagination-helper.service';

import { DisplayCounter } from '../model/page-counter.model';

@Pipe({
  name: 'paginationFilterPipe',
  pure: false,
})
export class PaginationFilterPipe implements PipeTransform {

  public transform(list: any, displayCounter: DisplayCounter): any {
    if (isNullOrUndefined(list) || list.length === 0) {
      displayCounter.setTotalSize(0);
      return list;
    }

    displayCounter.setTotalSize(list.length);

    return PaginationHelperService.buildDisplayArray(list, displayCounter.getDisplayedSize());
  }
}
