import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthSessionService } from '../auth-session.service';

import { AuthRoutingParams } from '../model/auth-routing-params.model';

@Component({
  selector: 'app-r6-logout',
  template: `
    <div>Dont rely on this. Extend it.</div>`,
})
export class LogoutComponent {

  constructor(private router: Router, private loginSessionService: AuthSessionService, private loginRoutingParams: AuthRoutingParams) {
  }

  processSignOut() {
    this.loginSessionService.performSignOut();
    const link = [this.loginRoutingParams.getLoginErrorRoute()];
    this.router.navigate(link);
  }
}
