export class DisplayCounter {

  displayedSize = 0;
  pageSize = 0;
  totalSize: number = -1;

  getDisplayedSize() {
    if (this.displayedSize === 0 && this.totalSize > 0) {
      this.displayedSize = this.pageSize;
    }
    if (this.totalSize > 0 && this.displayedSize > this.totalSize) {
      this.displayedSize = this.totalSize;
    }
    if (this.totalSize > 0 && this.displayedSize < this.totalSize && this.displayedSize < this.pageSize) {
      if (this.totalSize < this.pageSize) {
        this.displayedSize = this.totalSize;
      } else {
        this.displayedSize = this.pageSize;
      }
    }
    return this.displayedSize;
  }

  setDisplayedSize(displayedSize: number) {
    this.displayedSize = displayedSize;
  }

  setTotalSize(totalSize: number) {
    this.totalSize = totalSize;
  }

  getTotalSize() {
    return this.totalSize;
  }

  setPageSize(pageSize: number) {
    this.pageSize = pageSize;
  }

  getPageSize() {
    return this.pageSize;
  }
}
