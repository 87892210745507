import { CommonUtilsComponent } from '../services/common-utils-component';

import { DisplayCounter } from '../model/page-counter.model';

export abstract class PaginationHelper extends CommonUtilsComponent {

  displayCounter: DisplayCounter = new DisplayCounter();

  constructor(private pageSize: number) {
    super();
    this.displayCounter.setPageSize(pageSize);
    this.resetPagination();
  }

  onViewMore() {
    if (this.displayCounter.getDisplayedSize() + this.displayCounter.getPageSize() >= this.displayCounter.getTotalSize()) {
      this.displayCounter.setDisplayedSize(this.displayCounter.getTotalSize());
    } else {
      this.displayCounter.setDisplayedSize(this.displayCounter.getDisplayedSize() + this.displayCounter.getPageSize());
    }
  }

  showViewMore(): boolean {
    return this.displayCounter.getDisplayedSize() < this.displayCounter.getTotalSize();
  }

  onViewLess() {
    if (this.displayCounter.getDisplayedSize() - this.displayCounter.getPageSize() <= this.displayCounter.getPageSize()) {
      this.displayCounter.setDisplayedSize(this.displayCounter.getPageSize());
    } else {
      this.displayCounter.setDisplayedSize(this.displayCounter.getDisplayedSize() - this.displayCounter.getPageSize());
    }
  }

  showViewLess(): boolean {
    return this.displayCounter.getDisplayedSize() > this.displayCounter.getPageSize();
  }

  resetPagination() {
    if (this.displayCounter.getDisplayedSize() > this.displayCounter.getPageSize()) {
      this.displayCounter.setDisplayedSize(this.displayCounter.getPageSize());
    }
  }
}
