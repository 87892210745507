import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonUtilsModule } from '@runway/commonutils';
import { R6ApiModule } from '@runway/r6api';
import { ProductCommonModule } from '@runway/productcommon';

import { EstateService } from './estate.service';
import { EstateComponent } from './estate.component';
import { EstateListComponent } from './estate-list/estate-list.component';
import { EstateDetailComponent } from './estate-detail/estate-detail.component';
import { EstateDetailThemeComponent } from './estate-detail/estate-detail-theme.component';
import { EstateListFilterPipe } from './estate-list/estate-list-filter.pipe';
import { EstateDetailDirective } from './estate-detail/estate-detail.directive';

@NgModule({
  imports: [
    CommonModule,
    CommonUtilsModule,
    R6ApiModule,
    ProductCommonModule,
  ],
  declarations: [EstateComponent, EstateListComponent,
    EstateDetailComponent, EstateDetailThemeComponent,
    EstateListFilterPipe, EstateDetailDirective],
  exports: [EstateComponent, EstateListComponent,
    EstateDetailComponent, EstateDetailThemeComponent,
    EstateListFilterPipe, EstateDetailDirective],
  providers: [EstateService],
})
export class EstateModule {
}
