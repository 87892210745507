import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { isNullOrUndefined } from 'util';

import { ApiResponse } from '@runway/productcommon';
import { CookieService } from '@runway/commonutils';
import { R6ApiParams, R6ApiService } from '@runway/r6api';

import { AccesstokenModel } from './model/access-token.model';
import { ChangepasswordModel, ChangePasswordRequest } from './model/change-password-request.model';
import { PasswordresetModel, ResetPasswordRequest } from './model/reset-password-request.model';
import { SignonRequest } from './model/signon-request.model';
import { ValidateURLTokenRequest } from './model/validate-url-token-request.model';
import { UrltokenModel } from './model/url-token.model';

import { CookieParams } from './cookie-params.enum';
import { ProcessURLTokenRequest } from './model/process-url-token-request.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly signOnApiUrl: string = this.r6ApiService.r6ApiParams.getR6ApiUrl() + '/authapi/signon';

  private readonly changePasswordApiUrl: string = this.r6ApiService.r6ApiParams.getR6ApiUrl() + '/accessapi/password/change';
  private readonly resetPasswordApiUrl: string = this.r6ApiService.r6ApiParams.getR6ApiUrl() + '/accessapi/password/reset';

  private readonly urlServiceTokenProcessApiUrl: string = this.r6ApiService.r6ApiParams.getR6ApiUrl() + '/urlservice/token/process';
  private readonly urlServiceTokenValidateApiUrl: string = this.r6ApiService.r6ApiParams.getR6ApiUrl() + '/urlservice/token/validate';

  constructor(private r6ApiService: R6ApiService, private r6ApiParams: R6ApiParams, private cookieService: CookieService) {
  }

  performSignOn(signonRequest: SignonRequest): Observable<AccesstokenModel> {
    return this.r6ApiService.postWithResponse(this.signOnApiUrl, JSON.stringify(signonRequest));
  }

  changePassword(oldPassword: string, newPassword: string, accessName?: string, accessToken?: AccesstokenModel): Observable<ApiResponse> {
    const request: ChangePasswordRequest = new ChangePasswordRequest();
    const model = new ChangepasswordModel();
    model.accessname = !isNullOrUndefined(accessName) && accessName.length > 0 ? accessName : this.cookieService.get(CookieParams.r6AccessName);
    model.oldpassword = oldPassword;
    model.newpassword = newPassword;
    model.authenticationtoken = !isNullOrUndefined(accessToken) ? accessToken.securitytoken : this.cookieService.get(CookieParams.r6SecurityToken);
    request.changePassword = model;
    request.accessToken = accessToken;
    return this.r6ApiService.post(this.changePasswordApiUrl, JSON.stringify(request));
  }

  resetPassword(email: string): Observable<ApiResponse> {
    const request: ResetPasswordRequest = new ResetPasswordRequest();
    const model = new PasswordresetModel;
    model.accessname = [email];
    request.passwordReset = model;
    return this.r6ApiService.post(this.resetPasswordApiUrl, JSON.stringify(request));
  }

  urlTokenValidate(accessId: string, urlToken: string): Observable<UrltokenModel> {
    const request: ValidateURLTokenRequest = new ValidateURLTokenRequest();
    request.accessid = accessId;
    request.urltoken = urlToken;
    return this.r6ApiService.postWithResponse(this.urlServiceTokenValidateApiUrl, JSON.stringify(request));
  }

  urlTokenProcess(accessId: string, entityId: string, urlToken: string, tokenTypeId: string): Observable<ApiResponse> {
    const request: ProcessURLTokenRequest = new ProcessURLTokenRequest();
    request.accessid = accessId;
    request.entityid = entityId;
    request.tokentypeid = tokenTypeId;
    request.urltoken = urlToken;
    return this.r6ApiService.postWithResponse(this.urlServiceTokenProcessApiUrl, JSON.stringify(request));
  }
}
