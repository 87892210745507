import {NavigationEnd, Router} from '@angular/router';

import {isNullOrUndefined} from 'util';

export class JavaScriptUtilsService {

  // This method will perform number of operations upon a route..
  public static initOperationsOnRoute(router: Router) {
    router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // Scroll the view to top upon routing.
      window.scrollTo(0, 0);
      // Close any modal window upon routing.
      const lightboxClose = document.getElementById('html5-close');
      if (!isNullOrUndefined(lightboxClose)) {
        lightboxClose.click();
      }
    });
  }
}
