import { AccesstokenModel } from './access-token.model';

export class ChangepasswordModel {
  accessname: string;
  oldpassword: string;
  newpassword: string;
  authenticationtoken: string;
}

export class ChangePasswordRequest {
  changePassword: ChangepasswordModel;
  accessToken: AccesstokenModel;
}
