import { Pipe, PipeTransform } from '@angular/core';

import { isNullOrUndefined } from 'util';

import { StringCommons } from '../constants/string-commons.enum';

@Pipe({
  name: 'transformBoolean',
})
export class TransformBooleanPipe implements PipeTransform {

  transform(value: boolean, ...args: any[]): string {
    if (isNullOrUndefined(value)) {
      return StringCommons.EMPTY_STRING;
    }
    if (value) {
      return StringCommons.YES;
    } else {
      return StringCommons.NO;
    }
  }
}
