export class RangeFilter {
  displayValue: string;
  min: number;
  max: number;

  public static withinRange(range: RangeFilter, value: number): boolean {
    if (range === null) {
      return true;
    }
    if ((range.min === -1 || value >= range.min) && ((range.max === -1 || value < range.max))) {
      return true;
    }
    return false;
  }

  constructor(min: number, max: number, displayValue: string) {
    this.min = min;
    this.max = max;
    this.displayValue = displayValue;
  }
}
