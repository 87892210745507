import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { isNullOrUndefined } from 'util';

import { AuthService } from '../auth.service';
import { AuthSessionService } from '../auth-session.service';

import { AccesstokenModel } from '../model/access-token.model';
import { AuthRoutingParams } from '../model/auth-routing-params.model';
import { SignonRequest } from '../model/signon-request.model';

export const invalidLoginErrorMsg = 'The user name or password is incorrect.';

@Component({
  selector: 'app-r6-login',
  template: `
    <div>Dont rely on this. Extend it.</div>`,
})
export class LoginComponent implements OnInit {

  signOnReq: SignonRequest;
  signonRes: AccesstokenModel;

  rememberMe = false;
  userName: string;
  userPassword: string;

  errorMessage: string;
  signOnSuccess: boolean;

  currentDate: Date;

  numberOfRememberMeDays = 90;

  constructor(private router: Router, private authService: AuthService, private authSessionService: AuthSessionService, private authRoutingParams: AuthRoutingParams) {
    this.userName = '';
    this.userPassword = '';
    this.rememberMe = false;
    this.signOnSuccess = true;
    this.currentDate = new Date;
    this.errorMessage = '';
  }

  ngOnInit() {
    this.authSessionService.performSignOut();
  }

  processSignOn() {
    this.signOnReq = new SignonRequest();
    this.signOnReq.accessname = this.userName;
    this.signOnReq.accesspassword = this.userPassword;
    this.authService.performSignOn(this.signOnReq)
      .subscribe(
        response => {
          const success = this.setUserSession(response);
          if (this.signOnSuccess) {
            this.routeToLoginSuccess();
          }
        },
        error => this.setSignOnError(invalidLoginErrorMsg),
      );
  }

  setUserSession(signonResponse: AccesstokenModel) {
    this.signonRes = signonResponse;
    if (this.signonRes != null) {
      if (!isNullOrUndefined(this.signonRes.securitytoken)) {
        this.signOnSuccess = true;
        this.authSessionService.setLoginSession(this.signonRes, this.getCookieExpires());
      } else {
        this.signOnSuccess = false;
        this.errorMessage = invalidLoginErrorMsg;
        this.userPassword = '';
      }
    } else {
      this.signOnSuccess = false;
      this.errorMessage = invalidLoginErrorMsg;
      this.userPassword = '';
    }
  }

  setSignOnError(error: any) {
    this.signOnSuccess = false;
    this.errorMessage = error;
    this.userPassword = '';
  }

  routeToLoginSuccess(): void {
    const link = [this.authRoutingParams.getLoginSuccessRoute()];
    this.router.navigate(link);
  }

  getCookieExpires(): number | undefined {
    if (this.rememberMe) {
      return this.numberOfRememberMeDays;
    }
    return undefined;
  }
}
