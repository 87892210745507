import {CommonUtilsService} from '../services/common-utils.service';

export class CommonUtilsComponent {
    isEmpty = CommonUtilsService.isEmpty;
    isNotEmpty = CommonUtilsService.isNotEmpty;
    isValidNumber = CommonUtilsService.isValidNumber;
    isInvalidNumber = CommonUtilsService.isInvalidNumber;
    isValid = CommonUtilsService.isValid;
    isInvalid = CommonUtilsService.isInvalidNumber;
    concatStrings = CommonUtilsService.concatStrings;
    filterWebsitePublishedProducts = CommonUtilsService.filterWebsitePublishedProducts;
}
