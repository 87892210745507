import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CapsuleImageDirective } from './directives/capsule-image.directive';
import { CapsuleMasonaryDirective } from './directives/capsule-masonary.directive';
import { CapsuleSliderDirective } from './directives/capsule-slider.directive';

import { ActiveInactivePipe } from './pipes/active-inactive.pipe';
import { PaginationFilterPipe } from './pipes/pagination-filter.pipe';
import { TransformBooleanPipe } from './pipes/transform-boolean.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    CapsuleImageDirective,
    CapsuleMasonaryDirective,
    CapsuleSliderDirective,
    ActiveInactivePipe,
    PaginationFilterPipe,
    TransformBooleanPipe,
  ],
  exports: [
    ActiveInactivePipe,
    CapsuleImageDirective,
    CapsuleMasonaryDirective,
    CapsuleSliderDirective,
    PaginationFilterPipe,
    TransformBooleanPipe,
  ],
})
export class CommonUtilsModule {
}
