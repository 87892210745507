import { Injectable } from '@angular/core';

import { isNullOrUndefined } from 'util';

const secondInMilliSeconds = 1000;
const minuteInMilliSeconds = 60 * secondInMilliSeconds;
const hourInMilliSeconds = 60 * minuteInMilliSeconds;
const dayInMilliSeconds = 24 * hourInMilliSeconds;

@Injectable({
    providedIn: 'root'
})
export class CommonUtilsService {
    public static isEmpty(str: string): boolean {
        return isNullOrUndefined(str) || str.trim() === '';
    }

    public static isNotEmpty(str: string): boolean {
        return !CommonUtilsService.isEmpty(str);
    }

    public static isValidNumber(num: string): boolean {
        return !isNullOrUndefined(num) && !isNaN(+num);
    }

    public static isInvalidNumber(num: string): boolean {
        return !CommonUtilsService.isValidNumber(num);
    }

    public static isValid(data: any): boolean {
        return !isNullOrUndefined(data) && data !== '' && data !== 'undefined';
    }

    public static isInvalid(data: any): boolean {
        return !CommonUtilsService.isValid(data);
    }

    public static getFileNameFromUrl(url: string): string {
        if (!isNullOrUndefined(url)) {
            const pop = url.split('/').pop();
            if (!isNullOrUndefined(pop)) {
                return pop.split('#')[0].split('?')[0];
            }
        }
        return url;
    }

    /* String concatenation with deleting the duplicates. For example a, a b will return a b*/
    public static concatStrings(str1: string, str2: string): string {
        if (!isNullOrUndefined(str1) && !isNullOrUndefined(str2)) {
            if (str1.trim() === str2.trim()) {
                return str1.trim();
            }
            if (str1.trim().includes(str2.trim())) {
                return str1.trim();
            }
            return str1 + ' ' + str2;
        }

        if (!isNullOrUndefined(str1)) {
            return str1.trim();
        }

        if (!isNullOrUndefined(str2)) {
            return str2.trim();
        }
        return '';
    }

    public static filterWebsitePublishedProducts(productList: any[]): any[] {
        if (!isNullOrUndefined(productList)) {
            return productList.filter((product) => product.publishing
                && product.publishing.publishmethod === 'WEBSITE'
                && product.publishing.publishallowed === true
                && product.publishing.publishstatus === 'Published');
        } else {
            return null;
        }
    }

    public static getDays(timeInMillis: number) {
        return Math.floor(timeInMillis / dayInMilliSeconds);
    }

    public static getHours(timeInMillis: number) {
        const days = Math.floor(timeInMillis / (dayInMilliSeconds));
        timeInMillis -= days * (dayInMilliSeconds);
        return Math.floor(timeInMillis / hourInMilliSeconds) % 24;
    }

    public static getMinutes(timeInMillis: number) {
        const days = Math.floor(timeInMillis / dayInMilliSeconds);
        timeInMillis -= days * dayInMilliSeconds;
        const hours = Math.floor(timeInMillis / hourInMilliSeconds) % 24;
        timeInMillis -= hours * hourInMilliSeconds;
        return Math.floor(timeInMillis / minuteInMilliSeconds) % 60;
    }

    public static getSeconds(timeInMillis: number) {
        const days = Math.floor(timeInMillis / dayInMilliSeconds);
        timeInMillis -= days * dayInMilliSeconds;
        const hours = Math.floor(timeInMillis / hourInMilliSeconds) % 24;
        timeInMillis -= hours * 3600;
        const minutes = Math.floor(timeInMillis / minuteInMilliSeconds) % 60;
        timeInMillis -= minutes * 60;
        return timeInMillis % 60;
    }
}
