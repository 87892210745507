import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { isNullOrUndefined } from 'util';

import { R6ApiParams } from './r6api-params.model';

@Injectable({
  providedIn: 'root',
})
export class R6ApiService {

  constructor(public r6ApiParams: R6ApiParams, private http: HttpClient) {
  }

  post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(url, data, this.getHeadersAndParameters());
  }

  postWithResponse<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(url, data, this.getHeadersAndParameters());
  }

  list<T>(url: string, parameters?: HttpParams): Observable<T[]> {
    return this.http.get<T[]>(url, this.getHeadersAndParameters(parameters));
  }

  get<T>(url: string, parameters?: HttpParams): Observable<T> {
    return this.http.get<T>(url, this.getHeadersAndParameters(parameters));
  }

  getBinary(url: string, parameters?: HttpParams): Observable<string> {
    const headersAndParameters = this.getHeadersAndParameters(parameters);
    headersAndParameters['responseType'] = 'text';
    return this.http.get<string>(url, headersAndParameters);
  }

  postAsset<T>(url: string, data: any): Observable<T> {
    return this.postMultipart<T>(url, data);
  }

  postMultipart<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(url, data, this.getMultipartHeaders());
  }

  getHeadersAndParameters(parameters?: HttpParams) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json');
    if (!isNullOrUndefined(this.r6ApiParams.getR6AuthApiKey()) && this.r6ApiParams.getR6AuthApiKey().length > 0) {
      headers = headers.append('Authorization', this.r6ApiParams.getR6AuthApiKey());
    }
    if (!isNullOrUndefined(this.r6ApiParams.getR6SecurityToken()) && this.r6ApiParams.getR6SecurityToken().length > 0) {
      headers = headers.append('securitytoken', this.r6ApiParams.getR6SecurityToken());
    }

    if (isNullOrUndefined(parameters)) {
      return {
        headers: headers,
      };
    } else {
      return {
        headers: headers,
        params: parameters,
      };
    }
  }

  private getMultipartHeaders() {
    return {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'enctype': 'multipart/form-data',
        'Authorization': this.r6ApiParams.getR6AuthApiKey(),
        'securitytoken': this.r6ApiParams.getR6SecurityToken(),
      }),
    };
  }

}
