import { Pipe, PipeTransform } from '@angular/core';

const activeStr = 'Active';
const inactiveStr = 'Inactive';

@Pipe({
  name: 'activeinactivepipe',
})
export class ActiveInactivePipe implements PipeTransform {
  transform(active: boolean, ...args): string {
    if (active) {
      return activeStr;
    }
    return inactiveStr;
  }
}
