import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { mergeMap, publishReplay, take, startWith } from 'rxjs/operators';

import { isNullOrUndefined } from 'util';
import { R6ApiService } from './r6api.service';

@Injectable({
  providedIn: 'root'
})
export class R6ApiUtilsService {

  private cachedObservables = new Map<string, Observable<any>>();

  constructor(public r6ApiService: R6ApiService) { }

  public createObservableForList<T>(url: string, nonPeriodic?: boolean): Observable<T[]> {
    if (!isNullOrUndefined(nonPeriodic) && !nonPeriodic) {
      // return IntervalObservable.create(
      //    this.r6ApiService.r6ApiParams.getPollingIntervalForApi()).startWith(0).flatMap(
      //      (i) => this.r6ApiService.list(url));
      return this.r6ApiService.list(url);
    } else {
      return this.r6ApiService.list(url);
    }
  }

  public createObservable<T>(url: string, nonPeriodic?: boolean): Observable<T> {
    if (!isNullOrUndefined(nonPeriodic) && !nonPeriodic) {
      // return IntervalObservable.create(this.r6ApiService.r6ApiParams.getPollingIntervalForApi()).startWith(0).flatMap((i) =>
      //   this.r6ApiService.get(url));
        return this.cachedObservables.get(url);
    } else {
      if (!this.cachedObservables.get(url)) {
        const replayObservable = this.createReplayObservable(this.r6ApiService.get(url));
        //this.cachedObservables.set(url, replayObservable);
      }
      return this.cachedObservables.get(url);
    }
  }

  getR6ApiUrl(): string {
    return this.r6ApiService.r6ApiParams.getR6ApiUrl();
  }

  private createReplayObservable<T>(serviceObservable: Observable<Response>) {
    //return serviceObservable.publishReplay(1).refCount().take(1);
  }

}
