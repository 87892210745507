import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

declare const $: any;

@Directive({
    selector: '[appCapsuleImage]',
})
export class CapsuleImageDirective implements OnInit {

    @Input() lightBoxClass: string;
    @Input('masonaryGridClass') masonaryGridClass?: string;

    constructor(private el: ElementRef) {
    }

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        event.preventDefault();
    }

    ngOnInit() {
        this.triggerOnLoad();
    }

    private triggerOnLoad() {
        const nativeElement = this.el.nativeElement;
        nativeElement.onload = () => {
            if (this.masonaryGridClass) {
                $('.' + this.masonaryGridClass).masonry({
                    itemSelector: '.boxes',
                });
            }
            if (this.lightBoxClass) {
                $('.' + this.lightBoxClass).html5lightbox();
            }
        };
    }
}
