import { isNullOrUndefined } from 'util';

import { Dimension } from '../model/dimension.model';
import {
    canFitOnDimension,
    enerygyRatingDimension,
    garageAreaDimension,
    livingAreaDimension,
    masterBedroomAreaDimension,
    blockTypeDimension,
    blockWidthDimension,
    blockDepthDimension,
    blockAreaDimension,
    sectionDimension,
} from '../model/dimension-types.model';

export class ModelQueryService {

    public static getLivingArea(homeDimensions: Dimension[]): string {
        const compareLogic = (dimension: Dimension): boolean => dimension.name === livingAreaDimension.name && dimension.type === livingAreaDimension.type;
        return ModelQueryService.getDimensionValue(homeDimensions, compareLogic);
    }

    public static getMasterBedroomArea(homeDimensions: Dimension[]): string {
        const compareLogic = (dimension: Dimension): boolean => dimension.name === masterBedroomAreaDimension.name && dimension.type === masterBedroomAreaDimension.type;
        return ModelQueryService.getDimensionValue(homeDimensions, compareLogic);
    }

    public static getGarageArea(homeDimensions: Dimension[]): string {
        const compareLogic = (dimension: Dimension): boolean => dimension.name === garageAreaDimension.name && dimension.type === garageAreaDimension.type;
        return ModelQueryService.getDimensionValue(homeDimensions, compareLogic);
    }

    public static getCanFitOn(homeDimensions: Dimension[]): string {
        const compareLogic = (dimension: Dimension): boolean => dimension.name === canFitOnDimension.name && dimension.type === canFitOnDimension.type;
        return ModelQueryService.getDimensionValue(homeDimensions, compareLogic);
    }

    public static getEnergyRating(homeDimensions: Dimension[]): string {
        const compareLogic = (dimension: Dimension): boolean => dimension.name === enerygyRatingDimension.name && dimension.type === enerygyRatingDimension.type;
        return ModelQueryService.getDimensionValue(homeDimensions, compareLogic);
    }

    public static getBlockType(landDimensions: Dimension[]): string {
        const compareLogic = (dimension: Dimension): boolean => dimension.name === blockTypeDimension.name && dimension.type === blockTypeDimension.type;
        return ModelQueryService.getDimensionValue(landDimensions, compareLogic);
    }

    public static getBlockWidth(landDimensions: Dimension[]): string {
        const compareLogic = (dimension: Dimension): boolean => dimension.name === blockWidthDimension.name && dimension.type === blockWidthDimension.type;
        return ModelQueryService.getDimensionValue(landDimensions, compareLogic);
    }

    public static getBlockDepth(landDimensions: Dimension[]): string {
        const compareLogic = (dimension: Dimension): boolean => dimension.name === blockDepthDimension.name && dimension.type === blockDepthDimension.type;
        return ModelQueryService.getDimensionValue(landDimensions, compareLogic);
    }

    public static getBlockArea(landDimensions: Dimension[]): string {
        const compareLogic = (dimension: Dimension): boolean => dimension.name === blockAreaDimension.name && dimension.type === blockAreaDimension.type;
        return ModelQueryService.getDimensionValue(landDimensions, compareLogic);
    }

    public static getSection(landDimensions: Dimension[]): string {
        const compareLogic = (dimension: Dimension): boolean => {
            return dimension.name === sectionDimension.name && dimension.type === sectionDimension.type;
        };
        return ModelQueryService.getDimensionValue(landDimensions, compareLogic);
    }

    public static getDimensionValue(dimensions: Dimension[], compareLogic: Function): string {
        let dimensionValue = null;
        if (!isNullOrUndefined(dimensions)) {
            const dimensionFound = dimensions.find((dimension) => compareLogic(dimension));
            if (!isNullOrUndefined(dimensionFound)) {
                dimensionValue = dimensionFound.value;
            }
        }
        return dimensionValue;
    }
}
