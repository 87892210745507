import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'r6nm-r6api',
  template: `
    <p>
      r6api works!
    </p>
  `,
  styles: []
})
export class R6ApiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
