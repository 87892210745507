import { Component } from '@angular/core';

import { AuthService } from '../auth.service';

export const resetPasswordErrorMessage = 'Could not recover the password.';
export const resetPasswordSuccessMessage = 'New password has been sent to your email.';

@Component({
  selector: 'app-r6-reset-password',
  template: `
    <div>Dont rely on this. Extend it.</div>`,
})
export class ResetPasswordComponent {

  resetPasswordEmail: string;

  error = '';
  success = '';

  constructor(private authService: AuthService) {
  }

  resetPassword() {
    this.authService.resetPassword(this.resetPasswordEmail).subscribe(response => {
      this.resetPasswordEmail = '';
      this.error = '';
      this.success = this.getResetPasswordSuccessMessage();
    }, error => this.error = this.getResetPasswordErrorMessage());
  }

  getResetPasswordSuccessMessage(): string {
    return resetPasswordSuccessMessage;
  }

  getResetPasswordErrorMessage(): string {
    return resetPasswordErrorMessage;
  }
}
