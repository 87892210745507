import { ActivatedRoute } from '@angular/router';
import { Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, Type, ViewChild } from '@angular/core';

import { isNullOrUndefined } from 'util';

import { EstateDetailThemeService } from './estate-detail-theme.service';
import { EstateService } from '../estate.service';

import { EstateDetailComponent } from './estate-detail.component';
import { EstateDetailDirective } from './estate-detail.directive';

@Component({
  selector: 'app-estate-detail-theme-home[theme-class]',
  template: ' <ng-template appEstateDetailHost></ng-template>',
})
export class EstateDetailThemeComponent extends EstateDetailComponent implements OnInit {

  @ViewChild(EstateDetailDirective) appEstateDetailHost: EstateDetailDirective;

  constructor(public estateService: EstateService, public activatedRoute: ActivatedRoute,
    private themeService: EstateDetailThemeService, private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2, private elRef: ElementRef) {
    super(estateService, activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();
    this.activatedRoute.params.subscribe((params) => {
      const theme = params['theme'];
      if (!isNullOrUndefined(theme)) {
        this.loadComponent();
      }
    });
    this.loadComponent();
  }

  private loadComponent() {
    const themeComponent = this.findThemeComponent();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(themeComponent);
    const viewContainerRef = this.appEstateDetailHost.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent(componentFactory);
    if (this.theme) {
      this.renderer.setAttribute(this.elRef.nativeElement, 'theme-class', this.theme);
    } else {
      this.renderer.setAttribute(this.elRef.nativeElement, 'theme-class', this.themeService.getThemeByEstate(this.estateCpId));
    }
  }

  private findThemeComponent(): Type<any> {
    if (!isNullOrUndefined(this.theme)) {
      return this.themeService.getThemeByName(this.theme);
    }
    return this.themeService.getThemeForEstate(this.estateCpId);
  }
}
