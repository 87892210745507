export enum CookieParams {
  securityToken = 'securitytoken',
  signOnSession = 'signOnSession',
  selectedClientId = 'SELECTEDCLIENTID',
  selectedClientName = 'SELECTEDCLIENTNAME',
  r6AccessName = 'R6ACCESSNAME',
  r6ClientId = 'R6CLIENTID',
  r6LoginUserId = 'R6LOGINUSERID',
  r6SecurityToken = 'R6SECURITYTOKEN',
  r6UserName = 'R6USERNAME',
}

