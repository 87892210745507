export class EstateSummary {
    active: boolean;
    addressstate: string;
    childCount: number;
    clientcompanyid: string;
    clientid: string;
    clientproductid: number;
    commonReferenceId: number;
    cpidstring: string;
    currentstatusid: number;
    currentstatusname: string;
    developerCopy: boolean;
    developerCopyId: number;
    estatecolor: string;
    estatecpid: number;
    estatecpidstring: string;
    estateCpIdString: string
    estateWebUrl: string;
    imagepath: string;
    latitude: number;
    lastModifiedDate: number;
    locationid: number;
    locationname: string;
    longitude: number;
    lotsCount: number;
    masterCopy: boolean;
    masterCopyId: number;
    masterPlanUrl: string;
    ownerccid: string;
    ownername: string;
    productId: number;
    productname: string;
    producttypeid: number;
    r4Productid: string;
    regionId: number;
    regionName: string;
    r4LocationId: string;
    r4RegionId: string;
    sitingUrl: string;
    sourceReferenceId: number;
    statuscolor: string;
    suburb: string;
    masterPlanSvgUrl: string;
}
