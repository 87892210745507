export class LoanCalculatorParams {
    annualRate = 4.12;
    numberOfLoanYears = 30;

    getAnnualRate(): number {
        return this.annualRate;
    }

    getNumberOfLoanYears(): number {
        return this.numberOfLoanYears;
    }
}
