import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EstateService } from '../estate.service';
import { EstateSummary } from '../model/estate-summary.model';

@Component({
  selector: 'r6nm-estate-list',
  template: '<div>Estate List to be extended</div>'
})
export class EstateListComponent implements OnInit {

  estateList: EstateSummary[];
  estateListV2: EstateSummary[];
  selectedEstate: EstateSummary;
  selectedEstateV2: EstateSummary;
  estateListFilterText: string;

  constructor(public estateService: EstateService, public router: Router) {
    this.estateListFilterText = '';
  }

  ngOnInit() {
    this.listEstateSummary();
    this.listEstateSummaryV2();
  }

  listEstateSummary() {
    this.estateList = [];
    this.estateService.listEstateSummary()
      .subscribe((estateList) => {
        this.estateList = estateList;
      });
  }

  listEstateSummaryV2() {
    this.estateListV2 = [];
    this.estateService.listEstateSummaryV2()
      .subscribe((estateListV2) => {
        this.estateListV2 = estateListV2;
      });
  }

  onEstateClick(estate: EstateSummary) {
    this.selectedEstate = estate;
    const link = ['/estate-home', this.selectedEstate.cpidstring];
    this.router.navigate(link);
  }

  onEstateV2Click(estate: EstateSummary) {
    this.selectedEstateV2 = estate;
    const link = ['/estate-home', this.selectedEstateV2.estatecpidstring];
    this.router.navigate(link);
  }

  onStateClick(stateName: string) {
    this.estateListFilterText = stateName;
  }

}
