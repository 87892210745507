import {RangeFilter} from './range-filter.model';

export class SearchFilter {

  builder: string;
  frontage: number;
  minArea: number;
  maxArea: number;
  minPrice: number;
  maxPrice: number;
  noOfBedRooms: RangeFilter;
  noOfBathRooms: RangeFilter;
  priceRange: RangeFilter;

  // For House and Land filter
  lotCpId: string;
  estatename: string = null;
  addressstate: string = null;
  suburb: string = null;
}
