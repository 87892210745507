import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { R6ApiService } from './r6api.service';
import { R6ApiUtilsService } from './r6api-utils.service';
import { R6ApiComponent } from './r6api.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [R6ApiComponent],
  exports: [R6ApiComponent],
  providers: [R6ApiService, R6ApiUtilsService],
})
export class R6ApiModule {
}
